@import "../scss/color.scss";
@import "../scss/layout.scss";

body {
	.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
		color: $podpal-red-dark !important;
	}

	.ant-form-item-has-error .ql-toolbar,
	.ant-form-item-has-error .ql-container,
	.ant-form-item-has-error .ql-editor {
		border-color: $podpal-red-dark !important;
	}

	.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
		color: $podpal-green-light;
	}

	.ant-form-item-explain {
		font: $font-xs !important;
	}

	.ant-form-item-has-error .ant-form-item-explain,
	.ant-form-item-has-error .ant-form-item-split,
	.ant-form-item-has-error .ant-input-group-addon {
		color: $podpal-red-dark;
	}

	.ant-form-item-has-error .ant-input,
	.ant-form-item-has-error .ant-input-affix-wrapper,
	.ant-form-item-has-error .ant-input:hover,
	.ant-form-item-has-error .ant-input-affix-wrapper:hover,
	.ant-form-item-has-error .ant-input-group-addon {
		border-color: $podpal-red-dark;
	}

	/*
	Disables the arrows from the number inputs.
	*/
	.ant-input-number-handler-wrap {
		display: none;
	}

	.ant-input,
	.ant-input-password,
	.ant-input-number {
		border-radius: $radius-md;
	}

	.ant-input:hover,
	.ant-input:focus,
	.ant-input:active,
	.ant-input-password:hover,
	.ant-input-password.ant-input-affix-wrapper-focused,
	.ant-input-password:active,
	.ant-picker:hover,
	.ant-input-number:hover,
	.ant-input-number.ant-input-number-focused,
	.ant-input-number:active,
	.ant-picker-focused {
		border-color: $podpal-blue;
		box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5);
	}

	.ant-picker {
		border-radius: $radius-md;
	}

	.ant-input-password.ant-input-affix-wrapper-disabled:hover {
		border-color: $neutral-2;
	}

	.ant-select:hover,
	.ant-select:focus,
	.ant-select:active,
	.ant-select:hover .ant-select-selector,
	.ant-select:focus .ant-select-selector,
	.ant-select:active .ant-select-selector,
	.ant-select-focused .ant-select-selector,
	.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
		border-color: $podpal-blue !important;
		box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5);
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: $radius-md;
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		border-color: $podpal-blue;
	}

	.ant-select-dropdown .ant-select-item.ant-select-item-option {
		& .ant-select-item-option-content {
			color: $text-secondary;

			&:hover {
				color: $text-primary;
			}
		}
		&:hover {
			background-color: #f0f6ff !important;
		}

		// &.ant-select-item-option-active .ant-select-item-option-content {
		//     color: green;
		// }
	}

	// MOVE ASTERISKS FROM BEGINNING TO AFTER FORM LABEL
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&::before {
			display: none;
		}

		&::after {
			display: inline-block;
			margin-right: 4px;
			margin-top: -6px;
			color: #ff4d4f;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: "*";
		}
	}

	.ant-select-dropdown.ant-tree-select-dropdown {
		.ant-select-tree-treenode {
			color: $text-secondary;

			&:hover {
				background-color: #f0f6ff !important;
				color: $text-primary;
			}
		}
	}

	.ant-menu:not(.left-sidebar) .ant-menu-item {
		a {
			color: $text-secondary;
		}

		&:hover a {
			color: $text-primary;
		}
	}

	.ant-menu-submenu-title {
		color: $text-secondary;

		.ant-menu-submenu-arrow {
			color: $text-secondary;
		}
	}

	.ant-modal {
		width: 90%;
		@media only screen and (min-width: $xs) {
			min-width: 420px;
		}

		.ant-modal-confirm-btns {
			.ant-btn:not(.ant-btn-primary) {
				border-radius: $radius-md;
				color: $text-secondary;
				border-color: $neutral-2;
				background-color: $neutral-0;

				&:hover {
					color: $text-secondary;
					border-color: $neutral-2;
					background-color: $neutral-1;
				}
			}

			.ant-btn.ant-btn-primary {
				border-radius: $radius-md;
			}
		}
	}

	.ant-table {
		.ant-table-tbody > tr.ant-table-row:hover > td {
			background: $podpal-blue-ghost !important;
		}
	}

	.ant-tooltip {
		font: $font-sm !important;
		line-height: 20px !important;

		.ant-tooltip-arrow-content {
			background-color: $neutral-3;
		}

		.ant-tooltip-inner {
			background-color: $neutral-3 !important;
			padding: 12px 16px !important;
			border-radius: $radius-md;

			ul {
				margin-left: -24px !important;
				margin-top: 8px !important;
				/*margin-bottom: 3px !important;*/
				li {
					font-size: 14px !important;
					line-height: 22px !important;
					height: auto !important;
					/*margin-bottom: 3px !important;*/
				}
			}
		}
	}

	.ant-popconfirm {
		.ant-popover-content {
			max-width: 250px;
			height: fit-content;
			.ant-popover-inner-content {
				padding: $space-md !important;

				.ant-popover-message {
					font: $font-sm;
				}
				.ant-btn:hover:not(.ant-btn-primary),
				.ant-btn:focus:not(.ant-btn-primary) {
					background: #fff;
					color: $podpal-blue;
					border-color: $podpal-blue;
				}

				button.ant-btn-primary {
					background: $podpal-blue;
					border-color: $podpal-blue;

					&:hover,
					&:focus {
						transition: ease 0.1s;
						background: $podpal-blue-hover-dark;
						border-color: $podpal-blue-hover-dark;
					}
				}
			}
		}
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: $podpal-blue;
		border-color: $podpal-blue;
	}

	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: $podpal-blue;
	}

	.ant-divider-plain.ant-divider-with-text {
		color: $grey-7;
		font: $font-sm;
	}

	.ant-dropdown-menu {
		.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled) {
			color: $text-secondary;
		}
		.ant-dropdown-menu-item-active {
			background-color: $podpal-blue-ghost;
			color: $text-secondary;
		}

		.ant-dropdown-menu-item-danger:hover {
			color: $neutral-0 !important;
		}
	}
}

.ant-modal-wrap.antd-img-crop-modal {
	.ant-modal-content {
		border-radius: $space-xxs;
	}

	.ant-slider {
		&:hover {
			.ant-slider-handle {
				border-color: $podpal-blue;
			}
		}
		.ant-slider-handle {
			border-color: $podpal-blue;
		}
		.ant-slider-track {
			background-color: $podpal-blue;
		}
	}

	.ant-modal-footer {
		.ant-btn {
			border-radius: $space-xxs;
			border-color: $neutral-2;
			color: $text-secondary;
			background-color: $neutral-0;

			&:hover {
				background-color: $neutral-1;
				border-color: $neutral-2;
			}

			&.ant-btn-primary {
				background-color: $podpal-blue;
				border-color: $podpal-blue;
				color: $neutral-0;

				&:hover {
					background-color: $podpal-blue-hover-dark;
					border-color: $podpal-blue-hover-dark;
				}
			}
		}
	}
}
