@import "../../../../styles/scss/color.scss";
@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";

// .multiple-tree-select .ant-select {
//     width: 256px !important;
//     height: 40px !important;
// }

.multiple-tree-select .ant-select-selector {
	// box-sizing: border-box;
	border-radius: $radius-md !important;
	// width: 256px;
	// height: 40px;
}

.multiple-tree-select.ant-select:hover .ant-select-selector,
.multiple-tree-select.ant-select.ant-select-focused .ant-select-selector {
	border-color: $podpal-blue !important;
	box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5) !important;
}
