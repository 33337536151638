/** Proximity *******************/

$space-lg: 24px;
$space-md: 16px;
$space-sm: 12px;
$space-xs: 8px;
$space-xxs: 4px;

/** Borders  *******************/

$border-basic: 1px solid;

$radius-lg: 8px;
$radius-md: 4px;
$radius-sm: 2px;

// RESPONSIVE BREAKPOINTS SET USING MAX-WIDTH $XS, $MD, ETC
$xs: 576px;
$sm: 769px;
$md: 992px;
$lg: 1199px;
$xl: 1600px;

:export {
	spaceLg: $space-lg;
	spaceMd: $space-md;
	spaceSm: $space-sm;
	spaceXs: $space-xs;
	spaceXxs: $space-xxs;
	borderBasic: $border-basic;
	radiusLg: $radius-lg;
	radiusMd: $radius-md;
	radiusSm: $radius-sm;
	xs: $xs;
	sm: $sm;
	md: $md;
	lg: $lg;
	xl: $xl;
}
