@import "../../../styles/scss/color.scss";

.checklist-item {
	display: flex;
	align-items: center;
	width: 100%;
	user-select: none;
}

.dragging-checklist-item {
	background-color: $podpal-blue-ghost;
	border-radius: 8px;
	box-shadow: 2px 4px 12px 2px $podpal-blue-ghost;
}
