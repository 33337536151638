@import "../../../../../styles/scss/layout.scss";
@import "../../../../../styles/scss/type.scss";
@import "../../../../../styles/scss/color.scss";

.ant-modal.add-new-episode-confirmation-modal {
	h2 {
		color: $text-primary;
		font: $font-lg;
	}

	p {
		color: $grey-7 !important;
		font: $font-sm;

		&.bold-text {
			font-weight: 700 !important;
		}
	}

	.ant-modal-header {
		height: 56px;
	}

	.ant-modal-body {
		height: 339px;
	}

	.ant-modal-footer {
		height: 52px;
	}
}
