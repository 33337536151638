@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.image-upload {
	width: 128px !important;
	height: 128px !important;

	.image-upload-container,
	.image-upload-button {
		width: 128px !important;
		height: 128px !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.ant-upload {
		width: 128px !important;
		height: 128px !important;
		background: $neutral-1 !important;
		border-radius: $radius-lg !important;
	}

	.image-upload-image {
		width: 100% !important;
		border-radius: $radius-lg !important;
	}

	.image-upload-thumbnail-overlay {
		position: absolute;
		top: 0;
		left: 8;
		opacity: 0;
		// background: $neutral-3;
		background: #000;
		// margin-top: 20px !important;
		width: 128px !important;
		height: 128px !important;
		border-radius: $radius-lg !important;
		transition: all 0.2s ease-in-out;
		display: flex;
		justify-content: center;

		.image-upload-thumbnail-icon {
			color: $neutral-1;
			margin-top: 40%;
		}
	}

	.image-upload-container:hover {
		.image-upload-image,
		.image-upload {
			box-shadow: 0 0 0 8px $podpal-blue-ghost;
		}
		.image-upload-thumbnail-overlay {
			opacity: 0.55;
		}
	}
}
