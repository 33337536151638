@import "../../../../styles/scss/layout.scss";
@import "../ShowSettingsForm/ShowSettingsForm.scss";

.label {
	display: flex;
	align-items: center;
}

.logo {
	margin-right: $space-xs;
}

.social-form {
	max-width: 500px;
}
