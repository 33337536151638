@import "../../../styles/scss/color.scss";
@import "../../../styles/scss/type.scss";
@import "../../../styles/scss/layout.scss";

.empty-podcast-page {
	width: inherit !important;

	h2 {
		color: $neutral-3;
		font: $font-lg-plus;
	}

	p {
		color: $neutral-3;
		text-align: start;
		font: $font-md;
		font-weight: 400 !important;
	}

	@media only screen and (max-width: $xs) {
		p {
			text-align: center;
		}
	}
}
