@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.rss-and-distribution-page {
	max-width: 637px;
	padding-left: 1px; /* The cards were being cut off by the sidebar without this. */
	padding-top: $space-lg;
	.ant-form-item {
		margin-bottom: 0px;
		.ant-form-item-label {
			font: $font-sm;
			font-weight: 500;
			color: $text-primary;
		}

		.ant-form-item-children-icon {
			margin-right: 70px;
		}

		.ant-input-group-addon:last-of-type {
			padding: 0;
			border: none;

			.rss-url-copy-button {
				border-radius: 0 $radius-md $radius-md 0;
			}
		}
	}

	.rss-and-distribution-page-title {
		font: $font-md;
		color: $text-primary;
	}

	.rss-and-distribution-page-text {
		font: $font-md;
		font-weight: 400 !important;
		color: $text-secondary;
	}
}
