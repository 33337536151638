@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.upload-card {
	.ant-card-body {
		padding: $space-sm;
	}

	.anticon.anticon-spin {
		color: $podpal-blue;
	}

	.content {
		display: flex;
		align-items: center;
	}
	.filename {
		text-align: left;
		margin-left: $space-xs;
		flex-grow: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.progress {
		margin-top: $space-xs;

		.ant-progress-bg {
			background-color: $podpal-blue;
		}

		.processing {
			max-width: 90%;
		}
	}
}
