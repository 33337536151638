@import "../../../styles/scss/color.scss";
@import "../../../styles/scss/layout.scss";
@import "../../../styles/scss/type.scss";

.panel-header {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: $space-lg $space-lg $space-md $space-lg;
	text-align: center;

	&p {
		margin: 0;
	}

	.thumbnail-container {
		.thumbnail-overlay {
			position: absolute;
			top: $space-lg;
			opacity: 0;
			background: rgba(0, 0, 0, 0.6);
			width: 88px;
			height: 88px;
			border-radius: 8px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			.thumbnail-icon {
				color: $neutral-1;
				p {
					font: $font-xs;
					color: inherit;
				}
			}
		}

		.thumbnail {
			position: relative;
			border-radius: $radius-lg;
		}

		&:hover {
			.thumbnail {
				box-shadow: 0 0 0 8px $podpal-blue-ghost;
			}
			.thumbnail-overlay {
				opacity: 1;
			}
		}
	}

	.panel-icon-collapsed {
		margin-top: $space-xs !important;
		margin-bottom: -$space-md !important;
	}

	.show-settings-link {
		position: relative !important;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: $space-sm;
		line-height: $space-sm;
		margin-top: $space-xs !important;
		margin-bottom: $space-xs !important;
		color: $podpal-blue;

		&:hover {
			color: $podpal-blue-hover-dark;
			text-decoration: underline;
		}
	}

	.panel-podcast-name {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: $space-md;
		line-height: $space-lg;
		margin-top: $space-sm !important;
		font-feature-settings: "cpsp" on;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
		color: $text-primary;
	}
}

.podcast-dropdown {
	margin-bottom: $space-lg !important;

	.ant-collapse-content-box {
		padding-bottom: $space-lg !important;
	}
}

.left-sidebar {
	.ant-collapse {
		background: $neutral-0;
		.ant-collapse-header {
			padding: 0 !important;
			width: 224px;
		}
	}

	.ant-menu-inline-collapsed .ant-collapse {
		background: $neutral-0;
	}

	.more-podcasts {
		text-align: center;
		width: 176px;
		margin: 0 auto;
	}
}

.left-sidebar-collapsed {
	.ant-collapse {
		background: $neutral-0;
	}

	.ant-collapse-borderless .ant-collapse-header {
		padding: 0 !important;
	}

	.more-podcasts .ant-row {
		margin: 0 0 0 0 !important;
		padding: 0 0 0 0 !important;
	}
}

.more-podcasts {
	.ant-col-8 .ant-avatar {
		width: 48px;
		height: 48px;
		border-radius: $space-xs !important;
		font-size: 0px;
		line-height: 46px;
		margin-bottom: -$space-xs !important;

		&:hover {
			box-shadow: 0 0 0 $space-xxs $podpal-blue-ghost !important;
		}
	}

	.ant-col-24 .ant-avatar {
		width: 48px;
		height: 48px;
		/* margin-right: 200px; */
		border-radius: $space-xs !important;
		font-size: 0px;
		line-height: 46px;
		margin-bottom: $space-xs !important;

		&:hover {
			box-shadow: 0 0 0 $space-xxs $podpal-blue-ghost !important;
		}
	}

	.add-podcast-button {
		width: 48px;
		height: 48px;
		border-radius: $space-xs !important;
		border: none;
		background: #e8e8e8 !important;
		transition: ease 0.1s !important;

		&:hover {
			background: #e8e8e8;
			border: none;
			box-shadow: 0 0 0 $space-xxs $podpal-blue-ghost !important;
		}
	}
}
