@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.show-settings-form {
	margin-top: $space-lg !important;
	max-width: 528px; /* 256 + 256 + 16 gutter */

	.podcast-profile-url-field {
		.ant-form-item-children-icon {
			margin-right: 70px;
		}
		.ant-input-group-addon:last-of-type {
			padding: 0;
			border: none;

			.profile-url-visit-button {
				border-radius: 0 $radius-md $radius-md 0;
			}
		}
	}

	.itunes-visibility-radio-button {
		.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
			background: $podpal-red-dark;
			border-color: $podpal-red-dark;

			&:hover {
				background: $podpal-red-dark;
				border-color: $podpal-red-dark;
				color: $neutral-0;
			}
		}
	}

	.show-settings-podcast-artwork {
		margin-left: $space-xs !important;
	}

	.toggle-paragraph-text {
		margin-top: 30px;
		margin-left: -50px;
	}

	.footer-actions {
		position: sticky;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: $space-md 0 $space-xs 0;
		background-color: white;
	}
}

.show-settings-form p {
	margin-bottom: $space-lg !important;
}

.show-settings-form .ant-form-item-label {
	font: $font-sm;
	font-weight: 500;
	color: $text-primary;
}

.show-settings-form .show-settings-switch-container {
	display: flex;

	.ant-switch {
		margin-right: $space-lg;
	}
	p {
		font: $font-md;
	}
}

.show-settings-form .ant-collapse {
	max-width: 550px;
	border: none;
}

.show-settings-form .ant-collapse-item {
	border: none !important;
}

.show-settings-form .ant-collapse-header {
	background: $neutral-1 !important;
	font: $font-sm !important;
	font-weight: 500 !important;
	color: $neutral-3 !important;
}

.show-settings-form .ant-collapse .ant-collapse-content {
	border: none;
}

.show-settings-form .ant-collapse .ant-collapse-content-box p {
	font: $font-sm !important;
}

.show-settings-form .ant-collapse .show-delete-podcast-settings .ant-collapse-content-box p {
	color: #f1564b !important;
}

@media screen and (max-width: $xs) {
	.show-settings-form {
		.ant-select-selector {
			width: 100%;
		}

		.toggle-paragraph-text {
			margin: 0;
		}
	}
}
