/** Podpal Colors *******************/

/* Podpal Blue */
$podpal-blue: #4e73ff;
$podpal-blue-dark: #4463da;
$podpal-blue-hover-light: #789aff;
$podpal-blue-hover-dark: #3d57fe;
$podpal-blue-ghost: #f0f6ff;

/* Podpal Navy */
$podpal-navy-light: #3f3d56;
$podpal-navy-dark: #302d44;

/* Podpal Red */
$podpal-red-light: #f1564b;
$podpal-red-dark: #ea403b;
$podpal-red-extra-dark: #cf1322;

/* Podpal Green */
$podpal-green-light: #34cc8d;
$podpal-green-dark: #1eba8a;

/* Podpal Yellow */
$podpal-yellow-dark: #fb9b09;
$podpal-yellow-light: #fcba14;

/** Neutral Colors *******************/

$neutral-0: #fff;
$neutral-1: #f5f5f5; /* gray-3 */
$neutral-2: #d9d9d9;
$neutral-3: #262626;
$grey-7: #8c8c8c;
$grey-6: #b8b8b8; /* disabled button text */
$grey-4: #e8e8e8;
$grey-3: $neutral-1;
$grey-2: #fafafa;

/** Text Colors *******************/

$text-primary: #262626;
$text-secondary: #4d4d4d; // gray-8
$text-inverse: #fff;

:export {
	podpalBlue: $podpal-blue;
	podpalBlueDark: $podpal-blue-dark;
	podpalBlueHoverLight: $podpal-blue-hover-light;
	podpalBlueHoverDark: $podpal-blue-hover-dark;
	podpalBlueGhost: $podpal-blue-ghost;
	podpalRedLight: $podpal-red-light;
	podpalRedDark: $podpal-red-dark;
	podpalRedExtraDark: $podpal-red-extra-dark;
	podpalGreenLight: $podpal-green-light;
	podpalGreenDark: $podpal-green-dark;
	podpalYellowLight: $podpal-yellow-light;
	podpalYellowDark: $podpal-yellow-dark;
	neutral0: $neutral-0;
	neutral1: $neutral-1;
	neutral2: $neutral-2;
	neutral3: $neutral-3;
	gray7: $grey-7;
	gray6: $grey-6;
	gray4: $grey-4;
	gray3: $grey-3;
	gray2: $grey-2;
	textPrimary: $text-primary;
	textSecondary: $text-secondary;
	textInverse: $text-inverse;
}
