@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.new-episode-form-drawer {
	z-index: 2;
	/*
	TODO: Extract this into a PodpalAvatar component.
	*/
	.ant-avatar {
		border-radius: $radius-lg;
	}

	.ant-form-item-label {
		font: $font-sm;
		font-weight: 500;
		color: $text-primary;
	}

	.new-episode-form-panel-description {
		margin-bottom: $space-md;
	}

	.ant-collapse {
		max-width: 550px;
		border: none;

		.ant-collapse-content {
			border: none;
		}

		.ant-collapse-content-box p {
			font: $font-sm !important;
		}
	}

	.ant-collapse-item {
		border: none !important;
	}

	.ant-collapse-header {
		background: $neutral-1 !important;
		font: $font-sm !important;
		font-weight: 500 !important;
		color: $neutral-3 !important;
	}

	.button-field-container {
		display: flex;

		.ant-input {
			border-radius: $radius-md 0 0 $radius-md;
		}

		.ant-btn {
			border-radius: 0 $radius-md $radius-md 0;
		}
	}

	.itunes-visibility-radio-button {
		.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
			background: $podpal-red-dark;
			border-color: $podpal-red-dark;
			color: $neutral-0;

			&:hover {
				background: $podpal-red-dark;
				border-color: $podpal-red-dark;
				color: $neutral-0;
			}
		}
	}
}

@media screen and (max-width: $xs) {
	.new-episode-form-drawer {
		.ant-select-selector {
			width: 100% !important;
		}
	}
}

.video-remove-button {
	padding-top: $space-sm;
}

.form-tip {
	color: rgba(0, 0, 0, 0.45);
}
