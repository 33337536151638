@import "../../../../../styles/scss/layout.scss";
@import "../../../../../styles/scss/type.scss";
@import "../../../../../styles/scss/color.scss";

.ant-modal.new-email-modal {
	height: 250px !important;
	border-radius: 200px !important;
}

.ant-modal.new-email-modal .ant-modal-header {
	height: 56px !important;
}

.ant-modal.new-email-modal .ant-modal-header .ant-modal-title {
	font: $font-md !important;
	color: $text-primary !important;
}

.ant-modal.new-email-modal .ant-modal-body {
	min-height: 142px !important;

	.ant-form-item {
		margin-bottom: 0;
	}
}

.ant-modal.new-email-modal .ant-modal-body .ant-select ~ .ant-form-item-explain {
	margin-bottom: $space-xs !important;
}

.ant-modal.new-email-modal .ant-modal-body .ant-select-selector {
	box-sizing: border-box;
	border-radius: $radius-md;
	//   width: 369px !important;
	height: 40px !important;
}

.ant-modal.new-email-modal .ant-modal-body p {
	font: $font-sm !important;
	color: $text-secondary !important;
}

.ant-modal.new-email-modal .ant-modal-body .ant-input-password.ant-input-affix-wrapper input.ant-input {
	max-height: 38px;
	margin-top: -4px !important;
}

// .ant-modal.new-email-modal .ant-modal-footer {
//     height: 56px !important;
// }

.ant-modal.new-email-modal .grecaptcha-badge {
	display: none !important;
}

.ant-modal.new-email-modal .ant-modal-footer div {
	display: none !important;
}

.ant-modal.new-email-modal .ant-modal-footer button + button {
	margin: 0px !important;
}

.ant-modal.new-email-modal .new-email-modal-cancel-button {
	margin-right: $space-xs !important;
}

.ant-modal.new-email-modal input,
.ant-modal.new-email-modal .ant-input-password {
	box-sizing: border-box;
	border-radius: $radius-md;
	// width: 369px;
	height: 40px;
}

.ant-modal.new-email-confirmation-modal {
	max-width: 480px;
}

.ant-modal.new-email-confirmation-modal .ant-modal-header,
.ant-modal.new-email-confirmation-modal .ant-modal-content {
	border-radius: $radius-md !important;
}

.ant-modal.new-email-modal .ant-input:hover,
.ant-modal.new-email-modal .ant-input:focus,
.ant-modal.new-email-modal .ant-input:active,
.ant-modal.new-email-modal .ant-picker:hover,
.ant-modal.new-email-modal .ant-input-password:hover,
.ant-modal.new-email-modal .ant-input-password.ant-input-affix-wrapper-focused,
.ant-modal.new-email-modal .ant-picker-focused,
.ant-modal.new-email-modal .ant-select:hover .ant-select-selector,
.ant-modal.new-email-modal .ant-select.ant-select-focused .ant-select-selector {
	border-color: $podpal-blue;
	box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5);
}

.ant-modal.new-email-modal .ant-modal-body .ant-input-password.ant-input-affix-wrapper {
	input {
		background: none !important;
		margin-top: -6px !important;
		min-width: 214px !important;
	}

	.ant-input {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
}

.ant-modal.new-email-confirmation-modal img {
	text-align: center;
}

.ant-modal.new-email-confirmation-modal p {
	margin-bottom: 0px;
}

.ant-modal.new-email-confirmation-modal .ant-modal-body {
	text-align: center;
}

.ant-modal.new-email-confirmation-modal .new-email-confirmation-body-header {
	font: $font-lg;
	color: $text-primary;
	text-align: center;
	margin-bottom: $space-lg !important;
}

.ant-modal.new-email-confirmation-modal .new-email-confirmation-body-twofa-on {
	margin-top: $space-lg;
	font: $font-sm;
	color: #8c8c8c;
	text-align: center;
}

.ant-modal.new-email-confirmation-modal .new-email-confirmation-body-twofa-sure {
	font: $font-sm;
	color: #8c8c8c;
	text-align: center;
}
