@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.email-password-form {
	margin-top: $space-lg;
	max-width: 528px; /* 256 + 256 + 16 gutter */

	.ant-form-item-explain {
		min-height: 0px;
	}
	.ant-form-item-with-help .ant-form-item-explain {
		min-height: $space-lg;
	}

	.ant-form-inline .ant-form-item-with-help {
		margin-bottom: -24px;
	}

	.change-password-button {
		align-items: flex-start;
		margin-top: 20px;
	}

	.footer-actions {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: $space-md 0 $space-md $space-lg;
	}

	@media only screen and (max-width: $xs) {
		.form-button,
		.change-password-button,
		.current-password-form,
		.new-password-form {
			margin-top: $space-md;
		}

		.confirm-new-password {
			margin-top: $space-md;
		}

		.ant-form-item {
			width: 100%;
		}
	}
}

.email-password-form .ant-form {
	margin-bottom: $space-lg;
}

.email-password-form label {
	display: flex;
	align-items: center;
	font: $font-sm !important;
	font-weight: 500 !important;
	line-height: 12px !important;
	color: $text-primary;
	margin-bottom: $space-xs;
}

.email-password-form .ant-input:hover,
.email-password-form .ant-input:focus,
.email-password-form .ant-input:active,
.email-password-form .ant-input-password:hover,
.email-password-form .ant-input-password.ant-input-affix-wrapper-focused,
.email-password-form .ant-input-password:active,
.email-password-form .ant-picker:hover,
.email-password-form .ant-picker-focused {
	border-color: $podpal-blue;
	box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5);
}

.email-password-form input,
.email-password-form .ant-input-password {
	box-sizing: border-box;
	border-radius: $radius-md;
	width: 256px;
	height: 40px !important;

	@media only screen and (max-width: $xs) {
		& {
			width: 100%;
		}
	}
}

.email-password-form .ant-input-password {
	input {
		background: none !important;
		margin-top: -6px !important;
	}
	.ant-input {
		border-color: none !important;
		box-shadow: none !important;
	}

	&.ant-input-affix-wrapper input.ant-input {
		max-height: 38px;
		margin-top: -4px !important;
	}
}
