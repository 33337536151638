.notification-card {
	width: 100%;
	max-height: 184px;
	background: #ffffff;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	border-radius: 10px;

	&:hover {
		background: #f0f6ff;
	}

	.notification-card-avatar-text-container {
		height: 54px;
		width: 100%;
		gap: 8px;
		display: flex;
		align-items: center;
	}

	.notification-card-ago-badge-container {
		width: 100%;
		height: 24px;
		justify-content: space-between;
		display: flex;
		align-items: center;
	}

	.notification-card-message-container {
		width: 232px;
		display: flex;
	}

	.notification-card-message {
		font-family: Roboto;
		font-style: normal;

		font-size: 12px;
		line-height: 18px;
		/* or 150% */

		// display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		color: #4d4d4d;

		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		/* autoprefixer: off */
		// ^ Based on https://stackoverflow.com/questions/46152850/webkit-box-orient-styling-disappears-from-styling
		-webkit-line-clamp: 3;
	}

	.notification-badge-container {
		height: 16px;
	}

	.days-ago-text {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #8c8c8c;
	}

	.notification-card-rounded-rectangle {
		height: 56px;
		border: 0.5px solid lightgrey;
		border-radius: 8px;
		border-style: solid;
		display: flex;
	}

	.notification-card-rounded-rectangle-review-status-text {
		line-height: 22px;
		font-size: 14px;
	}

	.notification-card-rounded-rectangle-review-status-state-container {
		display: flex;
		gap: 8px;
		align-items: center;
		padding: 8px 16px;
	}

	.notification-card-rounded-rectangle-text {
		line-height: 20px;
		font-size: 12px;
		padding: 8px 16px;
		position: relative;
		height: 50px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		/* autoprefixer: off */
		// ^ Based on https://stackoverflow.com/questions/46152850/webkit-box-orient-styling-disappears-from-styling
		-webkit-line-clamp: 2;
	}

	.notification-card-button-container {
		display: flex;
		gap: 8px;
	}

	.notification-card-button {
		border-radius: 4px;
	}
}
