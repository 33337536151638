@import "../../../styles/scss/color.scss";
@import "../../../styles/scss/layout.scss";
@import "../../../styles/scss/type.scss";

.episode-card-container {
	.episode-card-title {
		a {
			font: $font-md;
			line-height: 16px;
			color: $text-primary;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

	.avatar-container {
		.avatar-overlay {
			background-color: $podpal-red-light;
			text-align: center;
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 96px;
			height: 14px;
			font-size: 12px;
			line-height: 15px;
			font-weight: 500;
			color: $neutral-0;
			border-radius: 0 0 7px 7px;
		}
	}

	.episode-card-podcast-artwork {
		border-radius: $space-xs !important;
	}

	.episode-card-season {
		font: $font-xs;
		color: $text-primary;
	}

	.episode-card-more .ant-dropdown-menu {
		border-radius: $space-xxs !important;
	}

	.episode-card-progress-text {
		font: 400 14px/14px Roboto, sans-serif;
	}
}
