.podcast-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	/* height: 100vh !important; */
	padding: 24px;
}

.podcast-page-heading {
	/* margin-left: 24px; */
	margin-top: 9px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	/* identical to box height, or 140% */

	display: flex;
	align-items: center;

	/* Grey / grey-9 */

	color: #262626;
}
