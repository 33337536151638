@import "../../../../../styles/scss/color.scss";
@import "../../../../../styles/scss/layout.scss";
@import "../../../../../styles/scss/type.scss";

.delete-podcast-modal {
	.ant-modal-body .ant-form-item {
		margin-bottom: 0;
	}

	.delete-podcast-modal-description {
		color: $text-secondary;
		font: $font-sm;
	}

	.delete-podcast-modal-confirmation-header {
		color: $text-primary;
		font: $font-lg;
	}

	.delete-podcast-modal-confirmation-bold-text {
		color: $grey-7;
		font: $font-sm;
		font-weight: 700;
	}

	.delete-podcast-modal-confirmation-text {
		color: $grey-7;
		font: $font-sm;
	}

	.delete-podcast-modal-text {
		font: $font-sm;
	}

	.grecaptcha-badge,
	.ant-modal-footer div {
		display: none;
	}

	.delete-podcast-modal-ok-button {
		margin-left: $space-xs;
	}
}
