@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.rss-redirect-container {
	overflow-y: scroll;
	h2 {
		font-size: 14px;
	}
	.rss-redirect-text {
		font: $font-md;
		font-weight: 400;
		color: $text-secondary;
		font-size: 14px;
	}
	.rss-redirect-text.rss-redirect-subheading {
		font-size: 16px;
	}
	.rss-redirect {
		max-width: 637px;
	}

	.ant-input-group-addon:last-of-type {
		padding: 0;
		border: none;

		.rss-url-copy-button {
			border-radius: 0 $radius-md $radius-md 0;
		}
	}
	.ant-checkbox-wrapper + .ant-checkbox-wrapper {
		margin-left: 0;
	}
	.ant-checkbox {
		align-self: flex-start;
		margin-top: 5px;
	}
}
