@import "../../../../styles/scss/color.scss";
@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";

.basic-select .ant-select-selector {
	box-sizing: border-box;
	border-radius: $radius-md !important;
}

.basic-select.ant-select:hover .ant-select-selector,
.basic-select.ant-select.ant-select-focused .ant-select-selector {
	border-color: $podpal-blue !important;
	box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5) !important;
}

@media screen and (max-width: $xs) {
	.basic-select .ant-select-selector {
		width: 100% !important;
	}
}
