@import "color.scss";
@import "layout.scss";
@import "type.scss";
@import "overrides.scss";

body {
	font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif;
	// padding: $space-lg;
}

h1 {
	font: $font-xl;
	line-height: 50px;
	color: $podpal-blue;
	margin: 20px 0;
}

h2 {
	font: $font-md;
}

p {
	font: $font-md;
	font-weight: 400;
	color: $text-primary;
	margin-bottom: 0px;
}

hr {
	margin-top: $space-md;
	border: none;
	background-color: $neutral-2;
	height: 1px;
}

a {
	color: $podpal-blue;
	&:hover {
		color: $podpal-blue-hover-dark;
	}
}

/** Buttons  *******************/

/** Large  ***/

.btn-lg-default {
	padding-left: $space-md;
	padding-right: $space-md;
	border: $border-basic;
	cursor: pointer;
}

/** Medium  ***/

.btn-md-default {
	padding-left: $space-md;
	padding-right: $space-md;
	border: $border-basic;
	cursor: pointer;
}

/** Small  ***/

.btn-sm-defualt {
	padding-left: $space-md;
	padding-right: $space-md;
	border: $border-basic;
	cursor: pointer;
}

/** Icon ***/

.btn-icon-defualt {
	padding-left: $space-md;
	padding-right: $space-md;
	border: $border-basic;
	cursor: pointer;
}

/** Form Fields  *******************/

form.container {
	margin-top: $space-md;
	width: 350px;
}

label.settings-form {
	font: $font-sm;
	color: $text-primary;
	display: block;
	align-items: center;
	line-height: 14px !important;
}

input.settings-form {
	font: $font-sm;
	color: $text-secondary;
	background-color: $neutral-0;
	border: $border-basic;
	border-color: $neutral-2;
	border-radius: $radius-md;
	padding-top: 0px !important;
}

button.settings-form {
	font: $font-sm;
	color: $text-secondary;
	background-color: $neutral-0;
	border-color: $neutral-2;
	border-radius: $radius-md;
}

button.settings-form:hover {
	color: $podpal-blue;
	border-color: $podpal-blue;
}

button.settings-form:active {
	color: $podpal-blue-dark;
	border-color: $podpal-blue-dark;
}

button.settings-form.save {
	font: $font-sm;
	color: $text-inverse;
	background-color: $podpal-green-light;
	border-color: $podpal-green-light;
	border-radius: $radius-md;
}

button.settings-form.save:hover {
	color: $text-inverse;
	border-color: $podpal-green-dark;
}

button.settings-form.save:active {
	color: $text-inverse;
	background-color: $podpal-green-dark;
	border-color: $podpal-green-dark;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: $neutral-2;
}
