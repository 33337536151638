@import "../../styles//scss/color.scss";
@import "../../styles//scss/layout.scss";
@import "../../styles//scss/type.scss";

.home-page .ant-menu.left-sidebar {
	z-index: 1; // so that the shadow shows above content in the page
	position: relative !important;
	width: 224px;
	height: 100% !important;
	font-size: 100px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-feature-settings: "cpsp" on;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	transition: transform 0.5s ease-in-out;
}

.left-sidebar .ant-menu-item {
	margin-bottom: 16px !important;

	a {
		color: $text-secondary;
	}
	&:hover a {
		color: $podpal-blue;
	}
}

.left-sidebar .ant-menu-inline .ant-menu-item {
	height: 38px !important;
}

.left-sidebar .ant-menu-item {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px !important;
	margin-left: 16px;
	border-radius: 24px;
	padding: 8px 20px !important;

	font-feature-settings: "cpsp" on;

	color: $text-secondary;
	transition: ease 0.1s !important;
}

.left-sidebar .ant-menu-item:hover {
	background-color: #f0f6ff;
}

.left-sidebar .ant-menu li.ant-menu-item:hover a {
	color: #4e73ff !important;
	transition: ease 0.1s !important;
}

.left-sidebar li.ant-menu-item.left-sidebar-expanded {
	width: min-content !important;
	height: 38px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
	transition: ease 0.1s !important;
}

.left-sidebar .ant-menu-item:hover .left-sidebar-menu-icon.anticon svg {
	fill: #4e73ff !important;

	path.microphone {
		stroke: $podpal-blue !important;
	}
}

.left-sidebar .ant-menu-item:hover .left-sidebar-menu-icon.anticon svg {
	transition: fill 0.2s;
}

.left-sidebar .ant-menu-item-selected {
	background-color: #ffffff !important;
}

.left-sidebar .ant-menu-item-selected a {
	color: #4e73ff !important;
	font-weight: 600 !important;
}

.left-sidebar .ant-menu-item-selected:hover {
	background-color: #f0f6ff !important;
}

.left-sidebar .ant-menu-item::after {
	border-right: 0px !important;
}

.home-page .ant-menu.left-sidebar-hidden {
	transition: all 0.5s ease-in-out;
	width: 0;

	& {
		transform: translateX(-224px);
		transition: all 0.5s ease-in-out;
	}
	& ~ .home-page-container,
	& ~ .banner-page-container {
		transition: width 0.5s ease-in-out;
	}
}

.ant-menu.left-sidebar-collapsed {
	position: absolute !important;
	height: 100% !important;

	& ~ .home-page-container,
	& ~ .banner-page-container {
		transform: translateX(80px);
		width: calc(100vw - 80px);
	}
}

.left-sidebar-collapsed.ant-menu-vertical .ant-menu-item {
	line-height: 46px !important;
}

.left-sidebar-collapsed .ant-menu-item:not(:last-child) {
	margin-bottom: 16px !important;
}

.left-sidebar-collapsed li.ant-menu-item.left-sidebar-expanded {
	height: 44px !important;
	padding: 0 0 0 0 !important;
	text-align: center !important;
	vertical-align: middle !important;
}

.left-sidebar-collapsed .ant-menu-item .left-sidebar-menu-icon.anticon,
.left-sidebar-collapsed .ant-menu-item-selected .anticon {
	line-height: 0 !important;
}

.left-sidebar-collapsed .ant-menu-item::after {
	border-right: 0px !important;
}

.left-sidebar-collapsed .ant-menu-item:active {
	background: #ffffff !important;
}

.left-sidebar-collapsed .ant-menu-item:hover .left-sidebar-menu-icon.anticon {
	box-shadow: 0 0 0 12px #f0f6ff;
	background: #f0f6ff;
	border-radius: 200px;
}

.left-sidebar-collapsed .ant-menu-item:hover .left-sidebar-menu-icon.anticon svg {
	fill: #4e73ff !important;
	transition: fill 0s;
}

.left-sidebar-collapsed .ant-menu-item-selected {
	background: #ffffff !important;
}

.left-sidebar-collapsed .ant-menu-item-selected:hover .anticon {
	box-shadow: 0 0 0 12px #f0f6ff;
	background: #f0f6ff !important;
	border-radius: 200px;
}

.left-sidebar-collapsed.ant-menu.ant-menu-root {
	position: absolute;
	width: 80px;
	height: 1146px;
	top: 64px;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.left-sidebar-collapsed .ant-collapse-header {
	padding: 0px 0px 0px 0px !important;
	padding-bottom: 16px !important;
}

.new-episode-button-container {
	margin-left: 24px;
}

.new-episode-collapsed-button-container {
	display: flex;
	justify-content: center;
	text-align: center;
}

.material-icons.home {
	background: #ffffff;
}
