@import "~antd/dist/antd.css";
@import "./styles/scss/style.scss";

html,
body {
	overflow: hidden;
	height: 100% !important;
}

/* TODO GLOBAL OVERRIDES */
/** Global Overrides **/

.ant-popover-inner {
	border-radius: 4px !important;
}

li.ant-menu-item {
	transition: ease 0.1s !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
	background-color: #4e73ff;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-item-selected {
	height: 40px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px;
	overflow: hidden;
	line-height: 45px;
	text-overflow: ellipsis;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
	margin-bottom: 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
	line-height: 0px;
}

/** Top Bar **/

.top-bar-popover-header-container {
	padding: 16px 16px 8px 16px;
}

.top-bar-popover-header-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: center;
}

.top-bar-popover-header-col {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: flex-start;
}

.top-bar-popover-header-divider {
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.top-bar-popover-menu-divider {
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}
