@import "../../../../../styles/scss/layout.scss";
@import "../../../../../styles/scss/type.scss";
@import "../../../../../styles/scss/color.scss";

.ant-collapse.distribution-card {
	.distribution-card-title {
		font: $font-md;
		color: $text-primary;
	}

	.distribution-card-description {
		font: $font-md;
		font-weight: 400 !important;
		color: $text-secondary;
	}

	.distribution-card-body-title {
		font: $font-md;
		color: $text-secondary;
	}

	.ant-form-item {
		.ant-form-item-label {
			font: $font-sm;
			font-weight: 500;
			color: $text-primary;
		}

		.ant-form-item-children-icon {
			margin-right: 70px;
		}

		.ant-input-group-addon:last-of-type {
			padding: 0;
			border: none;

			.distributor-url-save-button {
				border-radius: 0 $radius-md $radius-md 0;
			}
		}
	}
}
