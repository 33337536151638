@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.profile-form {
	margin-top: $space-lg;
	max-width: 528px; /* 256 + 256 + 16 gutter */

	.ant-form-item {
		margin-bottom: 0px;

		.ant-form-item-children-icon {
			margin-right: 4px;
		}
	}

	.ant-upload-picture-card-wrapper {
		margin-left: $space-xs;
	}

	.profile-url-container {
		.disabled-profile-form-field {
			/** Took width of the entire form and subtracted the button width **/
			width: 464.5px;
			border-radius: $radius-md 0 0 $radius-md;
		}

		.profile-url-copy-button {
			border-radius: 0 $radius-md $radius-md 0;
		}
	}

	.footer-actions {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: $space-md 0 $space-md $space-lg;
	}
}

.profile-form label {
	display: flex;
	align-items: center;
	font: $font-sm !important;
	font-weight: 500 !important;
	line-height: 12px !important;
	color: $text-primary;
	margin-bottom: $space-xs;
}

.profile-form .ant-input:hover,
.profile-form .ant-input:focus,
.profile-form .ant-input:active,
.profile-form .ant-picker:hover,
.profile-form .ant-picker-focused {
	border-color: $podpal-blue;
	box-shadow: 0px 0px 4px rgba(120, 154, 255, 0.5);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary {
	background: $podpal-blue;
	border: none;
}

.profile-form .ant-avatar-string {
	font-size: 36px !important;
}

.profile-form .ant-form-item-control {
	min-height: 64px;
}

.profile-form .ant-form-inline .ant-form-item-with-help {
	margin-bottom: 0px !important;
}

.profile-form .ant-upload.ant-upload-select-picture-card {
	// border-radius: 200px;
}

.profile-form .ant-upload {
	font-size: 24px;
}

.profile-form .ant-upload.ant-upload-select-picture-card {
	background: #ffffff !important;
}

.profile-form .ant-upload.ant-upload-select-picture-card:hover {
	border-color: $podpal-blue;
}

.profile-form .profile-form-thumbnail {
	width: 100%;
	border-radius: 200px;
	border: 3px solid #4e73ff;
}

.profile-form .profile-form-thumbnail-overlay {
	position: absolute;
	top: 0;
	left: 8;
	opacity: 0;
	// background: $neutral-3;
	background: #000;
	width: 128px !important;
	height: 128px !important;
	border-radius: 200px !important;
	transition: all 0.2s ease-in-out;

	.profile-form-thumbnail-icon {
		color: $neutral-1;
		position: absolute;
		top: 35%;
		left: 40%;
	}
}

.profile-form-thumbnail-container {
	width: 128px !important;
	height: 128px !important;
	&:hover {
		.profile-form-thumbnail {
			box-shadow: 0 0 0 8px $podpal-blue-ghost;
		}
		.profile-form-thumbnail-overlay {
			opacity: 0.55;
		}
	}
}

// .profile-form .profile-form-thumbnail:hover .profile-form.profile-form-thumbnail::before {
//     opacity: 50%;
//     background: red;
// }

.profile-form .ant-input-search {
	width: 256px;
	height: 40px;
}

.profile-form input {
	/* background: #FFFFFF; */
	/* Grey / grey-5 */

	/* border: 1px solid #D9D9D9; */
	box-sizing: border-box;
	border-radius: $radius-md;
	width: 256px;
	height: 40px;
}

.profile-form .long-profile-form-field {
	/** Took width of the entire form and subtracted the button width **/
	width: 528px;
	border-radius: 4px 0 0 4px !important;
}

.profile-form .long-profile-form-field .ant-input-group-addon {
	background: $neutral-1 !important;
	width: 125px !important;
	max-width: 125px !important;
	text-align: left !important;
}

.profile-form .ant-upload.ant-upload-select-picture-card {
	margin-bottom: $space-sm !important;
}

/** Tooltip */
.form-tip {
	padding-left: 4px;
}

.form-tip svg {
	/*color: #777;*/
	color: $text-secondary;
	transition-timing-function: ease;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: 0.8s;
	/*cursor: help;*/
}

.form-tip svg:hover {
	/*color: $text-primary;*/
	transition-timing-function: ease;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: 0.8s;
}

// TODO: EXTRACT ANT OVERRIDES TO GLOBAL

.ant-upload.ant-upload-select-picture-card {
	width: 128px !important;
	height: auto !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	background-color: #fafafa;
	border: none !important;
	border-radius: 2px;
	cursor: pointer;
	-webkit-transition: border-color 0.3s ease;
	transition: border-color 0.3s ease;
}

@media screen and (max-width: $xs) {
	.profile-form .ant-form .ant-form-item,
	.profile-form input,
	.profile-form .ant-input-search,
	.profile-form .ant-picker {
		width: 100% !important;
	}
	.profile-form .disabled-profile-form-field {
		/** Subtract the width of the copy button **/
		width: calc(100% - 63.5px) !important;
	}

	.profile-form .long-profile-form-field {
		width: 100% !important;
	}
}

.profile-form textarea {
	/* background: #FFFFFF; */
	/* Grey / grey-5 */

	/* border: 1px solid #D9D9D9; */
	box-sizing: border-box;
	border-radius: $radius-md;
	width: 528px;
	height: 133px !important;
}

.profile-form .ant-picker {
	width: 256px;
	height: 40px;
	border-radius: $radius-md;
}

#username-form button {
	background: $podpal-blue;
	border-radius: 0px 4px 4px 0px;
	height: 40px;
	color: #ffffff;
}

.ant-picker-input {
	border: 0px solid #d9d9d9 !important;
}
