@import "../../styles/scss/color.scss";
@import "../../styles/scss/layout.scss";
@import "../../styles/scss/type.scss";

.settings-page {
	display: flex;
	flex-direction: row;
	width: 100% !important;
}

.settings-page-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1 !important;
	padding: 24px !important;
	height: calc(100vh - 64px);
	width: 100% !important;
}

.settings-page-heading-container {
	max-height: 28px;
	display: flex;
	justify-content: flex-start;
}

@media screen and (max-width: $xs) {
	.settings-page-heading-container {
		max-height: 40px;
	}
}

.settings-page-heading {
	margin-top: 24px;
	margin-right: $space-sm;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	/* identical to box height, or 140% */
	display: flex;
	align-items: center;
	color: $text-primary;
}

.settings-page-tab-container {
	margin-top: 9px !important;
	font-family: Roboto;
	font-style: normal;
	font-size: 14px;
	line-height: 22px;
	/* 64 (top bar) + 24 (padding) + 28 (header) + 64 (footer) + 9 for leveling the divider and tabpane = 191 */
	// height: calc(100vh - 189px) !important;
	height: calc(100% - 90px);
}

.settings-page-tab-container .ant-tabs {
	height: 100% !important;
}

.settings-page-container .ant-tabs-content-holder {
	// height: calc(100% - 75px);
	// width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.settings-page-tab-container .ant-tabs-content {
	height: 100%;
}

.settings-page-tab-container .ant-tabs-tabpane {
	height: 100%;
}

.settings-page-tab-title-container {
	display: block;
}

.settings-page-tab-title-icon {
	vertical-align: middle !important;
}

.settings-page-tab {
	overflow-y: scroll;
	height: inherit !important;
	-ms-overflow-style: none;
}

.settings-page-tab::-webkit-scrollbar {
	display: none;
}

.settings-page-footer {
	position: relative;
	height: 6% !important;
}

.settings-page-tab-container .ant-tabs-bar,
.settings-page-container .ant-tabs-nav {
	margin: 0 !important;
}

.settings-page-tab-container .ant-tabs-tab {
	color: $text-secondary;

	a {
		color: inherit;
		transition: ease 0.1s !important;
	}
	.anticon svg {
		fill: $text-secondary;
	}

	&.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			font-weight: 500;
			color: $podpal-blue;
		}

		.anticon svg {
			fill: $podpal-blue;
		}
	}

	&:hover {
		a {
			color: $podpal-blue;
			transition: ease 0.1s !important;
		}
		.anticon svg {
			fill: $podpal-blue;
		}
	}
}

.settings-page-tab-container .ant-tabs-ink-bar {
	background-color: $podpal-blue !important;
}
