.ant-menu.user-settings-sidebar {
	display: flex;
	flex-direction: column;
	font-family: Roboto;
	width: 224px !important;
	min-width: 224px !important;
	height: 100vh;
	background: #f5f5f5;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
	z-index: 1;
}

.ant-menu.user-settings-sidebar.us-sidebar-enter {
	transform: translateX(0px) !important;
}

.ant-menu.user-settings-sidebar.us-sidebar-enter-done,
.ant-menu.user-settings-sidebar.us-sidebar-enter-done ~ .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-enter-done ~ div .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-enter-done ~ .empty-podcast-page {
	transform: translateX(-224px) !important;
	transition: transform 500ms ease;
}

.ant-menu.user-settings-sidebar.us-sidebar-enter-done > .user-settings-sidebar-footer,
.ant-menu.user-settings-sidebar.us-sidebar-exit-done > .user-settings-sidebar-footer {
	margin-bottom: 80px;
}

.ant-menu.user-settings-sidebar.us-sidebar-exit,
.ant-menu.user-settings-sidebar.us-sidebar-exit ~ .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-exit ~ div .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-exit ~ .empty-podcast-page {
	transform: translateX(-224px) !important;
	transition: transform 500ms ease;
}

.ant-menu.user-settings-sidebar.us-sidebar-exit-done,
.ant-menu.user-settings-sidebar.us-sidebar-exit-done ~ .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-exit-done ~ div .settings-page-container,
.ant-menu.user-settings-sidebar.us-sidebar-exit-done ~ .empty-podcast-page {
	transform: translateX(0px);
	transition: transform 500ms ease;
}

.user-settings-sidebar-header {
	display: flex;
	margin: 24px 16px !important;
}

.user-settings-sidebar-header-content {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding-left: 12px;
}

.user-settings-header-name {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	padding-top: 12px;
	display: flex;
	align-items: flex-end;

	color: #262626;
}

.user-settings-header-settings-text {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	padding-top: 4px;
	font-feature-settings: "cpsp" on;

	color: #262626;
}

.ant-divider-horizontal.user-settings-sidebar-header-divider {
	min-width: 200px !important;
	width: 200px !important;
	align-self: center;
	height: 0px;
	border: 1px solid #e8e8e8;
	margin: 0 0 24px 0 !important;
}

.user-settings-sidebar .ant-menu-item:not(:last-child) {
	margin-top: 0px !important;
	margin-bottom: 16px !important;
}

.user-settings-sidebar .ant-menu-item {
	margin-left: 16px !important;
	background: #f5f5f5 !important;
	width: 176px !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	padding: 0 0 0 16px !important;
}

.user-settings-sidebar .ant-menu-item:hover {
	color: #4e73ff !important;
	width: 176px !important;
	// overflow: visible !important;
	border-radius: 4px;
}

.user-settings-sidebar .ant-menu-item a:hover {
	color: #4e73ff !important;
}

.user-settings-sidebar .ant-menu-item-selected {
	background: #e8e8e8 !important;
	width: 176px;
	border-radius: 4px;
}

.user-settings-sidebar .ant-menu-item-selected a {
	color: #4e73ff !important;
	background: #e8e8e8 !important;
	font-weight: 500;
	border-radius: 4px;
}

.user-settings-sidebar .ant-menu-item-selected a:hover {
	font-weight: 500 !important;
}

.user-settings-sidebar .ant-menu-item::after {
	border-right: 0px !important;
}

.user-settings-sidebar .ant-menu-submenu {
	margin: 0 0 16px 0 !important;
	padding: 0 0 0 0 !important;
	margin-left: 32px !important;
}

.user-settings-sidebar .ant-menu-submenu-title {
	margin: 0 0 0 0 !important;
	padding: 0 0 0 0 !important;
}

.user-settings-sidebar .ant-menu-submenu .ant-menu {
	background: #f5f5f5 !important;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 284px;
}

/* .user-settings-sidebar .ant-menu-submenu .ant-menu::-webkit-scrollbar {
    /* width: 6px !important;
    height: 10px !important; */
/* background: #F5F5F5;
} */

.user-settings-sidebar .ant-menu-submenu .ant-menu .ant-menu-item:first-child {
	margin-top: 16px !important;
}

.user-settings-sidebar .ant-menu-submenu .ant-menu .ant-menu-item {
	background: #f5f5f5 !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	text-overflow: ellipsis;
}

.user-settings-sidebar .ant-menu-submenu .ant-menu .ant-menu-item-selected {
	color: #4e73ff !important;
	background: #e8e8e8 !important;
	font-weight: normal;
	width: 176px !important;
	border-radius: 4px;
}

.user-settings-sidebar .ant-menu-submenu .ant-menu .ant-menu-item:hover {
	color: #4e73ff !important;
	width: 176px !important;
	border-radius: 4px;
}

.user-settings-sidebar .ant-menu-submenu-active .ant-menu-submenu-title,
.user-settings-sidebar .ant-menu-submenu-selected .ant-menu-submenu-title {
	color: #4e73ff !important;
}

.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
	background: #4d4d4d !important;
}

.user-settings-sidebar .ant-menu-submenu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.user-settings-sidebar .ant-menu-submenu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
	background: #4e73ff !important;
}

.user-settings-sidebar-footer {
	position: absolute;
	bottom: 0;
	margin-bottom: 16px;
	text-align: center;
	width: 224px;
}

@media screen and (max-height: 550px) {
	.user-settings-sidebar-footer {
		display: none !important;
	}
}
.ant-menu-submenu-open {
	@media screen and (max-height: 800px) {
	}
}

.back-to-podcast-button {
	width: 32px !important;
	height: 32px !important;
	background: #f5f5f5 !important;
	border: none !important;
	vertical-align: middle !important;
}

.back-to-podcast-button:hover {
	background: #e8e8e8 !important;
	border-radius: 100% !important;
}

.back-to-podcast-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	margin-left: 4px !important;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: #4d4d4d;
	vertical-align: middle;
	margin-right: 24px !important;
}
