@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/color.scss";

.radio-button-group.ant-radio-group {
	display: flex;

	.ant-radio:hover {
		border-color: $podpal-blue;
	}

	.ant-radio-checked {
		.ant-radio-inner {
			border-color: $podpal-blue;
		}
	}

	.ant-radio-button-wrapper:first-child {
		border-radius: $radius-md 0px 0px $radius-md;
	}

	.ant-radio-button-wrapper:last-child {
		border-radius: 0px $radius-md $radius-md 0px;
	}

	.ant-radio-button-wrapper-disabled {
		color: $grey-6;
	}

	.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
		border-right-color: $podpal-blue;
	}

	.ant-radio-button-wrapper:not([class*=" ant-radio-button-wrapper-disabled"]):hover {
		color: $podpal-blue;
	}

	.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
		background: $podpal-blue;
		border-color: $podpal-blue;
		color: $neutral-0;

		&:hover {
			background: $podpal-blue-hover-dark;
			border-color: $podpal-blue-hover-dark;
			color: $neutral-0;
		}
	}

	&.stacked-radio-button {
		flex-direction: column !important;
	}

	@media screen and (max-width: $xs) {
		&.stacked-on-mobile-radio-button {
			display: inline-flex !important;
			flex-direction: column !important;

			.ant-radio-button-wrapper:first-child {
				border-radius: $radius-md $radius-md 0px 0px !important;
			}

			.ant-radio-button-wrapper:last-child {
				border-radius: 0px 0px $radius-md $radius-md !important;
			}
		}
	}
}
