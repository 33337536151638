@import "../../../styles/scss/color.scss";

/** Help Center Icon *****/

.help-center-icon {
	transition: ease 0.25s;
	box-shadow: 0 0 0 8px transparent;
	border-radius: 200px;
}

.help-center-icon:hover {
	transition: ease 0.25s;
	box-shadow: 0 0 0 8px $podpal-blue-dark;
	background: $podpal-blue-dark;
	border-radius: 200px;
	cursor: pointer;
}

/* TODO: IS THIS AN OVERRIDE? */
.ant-badge-dot {
	position: absolute;
	top: 2px !important;
	right: 4px !important;
	z-index: auto;
	width: 8px !important;
	height: 8px !important;
	background: $podpal-red-light !important;
	border: 0px !important;
	border-radius: 100%;
	-webkit-box-shadow: 0 0 0 0px #fff !important;
	box-shadow: 0 0 0 0px #fff !important;
}

/** Help Center Dropdown Container *****/

.help-center-overlay {
	width: 256px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border-radius: 4px;

	.ant-badge-count {
		p {
			color: $neutral-0;
			margin-bottom: 0px;
			font-weight: 600 !important;
			font-size: 11px !important;
			line-height: 20px;
			white-space: nowrap;
			text-align: center;
			height: 20px;
		}
	}

	.ant-menu {
		border-radius: 0px 0px 4px 4px !important;
	}

	.ant-menu-vertical .ant-menu-item {
		height: 32px !important;
		padding-left: 16px !important;
		display: flex;
		align-items: center;
		/* Grey / grey-8 */
		color: $text-secondary;
	}

	ul.ant-menu li.ant-menu-item:last-child {
		margin-bottom: 8px !important;
	}

	.ant-menu-vertical .ant-menu-item:hover {
		background-color: $podpal-blue-ghost;
		color: $text-secondary;
	}

	.ant-menu-vertical .ant-menu-item-selected {
		background-color: $podpal-blue-ghost !important;
		color: $text-secondary;
	}
}

.help-center-overlay ul.ant-menu li.ant-menu-item-selected {
	background-color: $podpal-blue-ghost !important;
	color: $text-primary;
}

.help-center-overlay ul.ant-menu li.ant-menu-item:hover {
	color: $text-primary;
}

.help-center-text {
	padding-top: 16px;

	order: 0;
	flex: 0 0 0;
	align-self: auto;

	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	/* identical to box height, or 150% */
	align-items: center;
	font-feature-settings: "cpsp" on;
	/* Grey / grey-9 */
	color: $text-primary;
}

.learn-more-text {
	order: 1;
	flex: 0 0 0;
	align-self: auto;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	align-items: center;
	color: $podpal-blue;
	padding-top: 8px;
}

.learn-more-text:hover {
	color: $podpal-blue;
	text-decoration: underline;
}

/*** Helpcenter Dropdown Contents ********/

.help-center-image {
	margin-left: 0px;

	img {
		margin-left: 10px;
		width: 88px;
	}
}

.new-updates-badge {
	margin-left: 8px !important;
	min-width: 24px !important;
	border: none !important;
}

/* TODO: IS THIS AN OVERRIDE? */
/** temp fix for top bar popover alignment **/
.ant-popover-placement-bottomRight {
	top: 55px !important;
}
