@import "styles/scss/layout.scss";
@import "styles/scss/type.scss";
@import "styles/scss/color.scss";

.change-release-date-modal {
	p {
		color: $text-secondary !important;
		font: $font-sm;
	}
}
