/** Font Stacks *******************/
$font-xxl: 900 38px/46px Roboto, sans-serif;
$font-xl: 500 50px/48px Roboto, sans-serif;
$font-lg-plus: 700 30px/38px Roboto, sans-serif;
$font-lg: 500 20px/28px Roboto, sans-serif;
$font-md: 500 16px/24px Roboto, sans-serif;
$font-sm: 400 14px/22px Roboto, sans-serif;
$font-xs: 400 12px/20px Roboto, sans-serif;

:export {
	fontXxl: $font-xxl;
	fontXl: $font-xl;
	fontXs: $font-xs;
	fontLgPlus: $font-lg-plus;
	fontLg: $font-lg;
	fontMd: $font-md;
	fontSm: $font-sm;
	fontXs: $font-xs;
}
