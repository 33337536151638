@import "../../../styles/scss/layout.scss";
@import "../../../styles/scss/type.scss";

.apps-and-integrations-card {
	border-radius: $radius-lg;
	height: 216px;
	width: 204px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	padding: $space-md;
	margin-bottom: $space-lg;
	.title {
		padding-bottom: $space-md;
		.title-text {
			font: $font-md;
			align-self: center;
		}
		.icon {
			max-width: 32px;
			max-height: 32px;
		}
	}

	.body {
		height: 76px;
	}
	.action {
		align-self: flex-end;
	}
}
