@import "../../../styles/scss/color.scss";

.user-settings-dropdown.ant-avatar:hover {
	transition: ease 0.25s;
	box-shadow: 0 0 0 4px #4463da;
	border-radius: 200px;
	cursor: pointer;
}
/** User Settings Dropdown Container *****/

/* Removes default border-right prop from Ant submenu */
.ant-menu-vertical {
	border: 0px !important;
}

.user-settings-overlay {
	min-width: 256px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	border-radius: 4px;
}

.user-settings-overlay .ant-avatar {
	min-width: 56px !important;
}

.user-settings-overlay .ant-menu-vertical .ant-menu-item {
	height: 32px !important;
	padding-left: 16px !important;
	display: flex;
	align-items: center;
	/* Grey / grey-8 */
	color: #4d4d4d;
}

.user-settings-overlay .ant-menu {
	border-radius: 0px 0px 4px 4px !important;
}
.user-settings-overlay ul.ant-menu li.ant-menu-item-selected {
	background: #fff !important;
	color: #4d4d4d;
}

.user-settings-overlay ul.ant-menu li.ant-menu-item:hover {
	background-color: #f0f6ff !important;
	color: #4d4d4d !important;
}

.user-settings-overlay ul.ant-menu li.ant-menu-item:hover {
	transition: background-color 0.015s ease;
	background-color: #f0f6ff !important;
}

.user-settings-overlay ul.ant-menu li.ant-menu-item-selected a {
	color: #262626 !important;
}

.user-settings-overlay ul.ant-menu li.ant-menu-item:last-child {
	margin-bottom: 8px !important;
}

/** User Settings Dropdown Content *****/
.ant-popover-inner-content {
	padding: 0px 0px 0px 0px !important;
}
.user-settings-name-text {
	position: relative;
	padding: 0px 0px 0px 12px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	/* identical to box height, or 150% */

	align-items: center;
	font-feature-settings: "cpsp" on;
	/* Grey / grey-9 */
	color: #262626;
}
.user-settings-email-text {
	position: relative;
	padding: 4px 0px 0px 12px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;

	align-items: center;

	/* Grey / grey-8 */

	color: #4d4d4d;
}

/** temp fix for top bar popover alignment **/
.ant-popover-placement-bottomRight {
	top: 55px !important;
}
