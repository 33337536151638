@import "../../../styles/scss/color.scss";

/** Notifications Icon *****/

.notifications-dropdown.ant-badge {
}

.notifications-icon {
	transition: ease 0.25s;
	box-shadow: 0 0 0 8px transparent;
	border-radius: 200px;

	&:hover {
		transition: ease 0.25s;
		box-shadow: 0 0 0 8px $podpal-blue-dark;
		background: $podpal-blue-dark;
		border-radius: 200px;
		cursor: pointer;
	}
}

/* TODO: IS THIS AN OVERRIDE? */
.notifications-dropdown .ant-badge-count {
	p {
		color: $neutral-0;
		margin-bottom: 0px;
		font-weight: 600 !important;
		font-size: 11px !important;
		line-height: 20px;
		white-space: nowrap;
		text-align: center;
		height: 20px;
	}
	position: absolute;
	min-width: 20px;
	height: 20px;
	padding: 0 6px;
	color: #fff;
	font-weight: 600 !important;
	font-size: 11px !important;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	background: $podpal-red-light !important;
	border-radius: 10px;
	border: 0px !important;
	-webkit-box-shadow: 0 0 0 0px #fff !important;
	box-shadow: 0 0 0 0px #fff !important;
	z-index: auto;
}

/** Notifications Dropdown Container *****/

.notifications-overlay {
	width: 320px;
	border-radius: 4px;
	display: flex;
	max-height: calc(100vh - 72pt);
	flex-direction: column;
}

.notifications-overlay .ant-divider.top-bar-popover-header-divider {
	margin-bottom: 0px !important;
}

.no-notifications-overlay {
	width: 320px;
	padding-bottom: 24px;
}

/** Notifications Dropdown Contents *****/

.notifications-text {
	padding-top: 8px;

	order: 0;
	flex: 0 0 100%;
	align-self: auto;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	font-feature-settings: "cpsp" on;
	color: $text-primary;
}

.recent-activity-text {
	padding: 16px 0px 0px 0px;

	order: 0;
	flex: 0 0 50%;
	align-self: auto;

	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	/* identical to box height, or 167% */
	color: $grey-7;
}

.mark-all-read-text {
	padding: 16px 0px 0px 0px;

	order: 1;
	flex: 0 0 50%;
	align-self: auto;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
	text-align: right;
	color: $podpal-blue;
}

.mark-all-read-text:hover {
	cursor: pointer;
	text-decoration: underline;
}

.notifications-container {
	width: 100%;
	overflow-y: scroll;
	padding-bottom: 8px;
	padding-left: 8px;
}

/** No New Notifications Dropdown Contents *****/

.no-new-notifications-image {
	padding-top: 8px;
	width: 190px;
	margin: 0 auto;
}

.no-new-notifications-image img {
	width: 190px;
	height: 174px;
}

.no-new-notifications-text-container {
	padding-top: 16px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-size: 14px;
	line-height: 22px;
	color: $grey-7;
}

/* TODO: IS THIS AN OVERRIDE? */
/** temp fix for top bar popover alignment **/
.ant-popover-placement-bottomRight {
	top: 55px !important;
}
