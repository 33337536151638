@import "../../styles/scss/type.scss";
@import "../../styles/scss/layout.scss";
@import "../../styles/scss/color.scss";

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.get-started-page {
	min-height: 100vh;

	.ant-layout-header {
		background: transparent;
		padding: 0 35px;
		z-index: 1;
		.logo {
			max-height: 64px;
		}

		.already-member-text {
			font: $font-md;
			font-weight: 400;
			color: $text-secondary;
		}
		@media screen and (max-width: $lg) {
			background: $neutral-0;
		}
		@media screen and (max-width: $xs) {
			& {
				background: $podpal-blue;
				.already-member-text {
					display: none;
				}

				.logo {
					max-height: 30px;
				}
			}
		}
	}

	.ant-layout-content {
		background: #fff;
		transform: translateY(-64px);
		overflow-y: hidden;
		height: 100vh;
		overflow-x: hidden;

		@media screen and (max-width: $lg) {
			& {
				overflow-y: scroll;
			}
		}
		@media screen and (max-width: $xs) {
			& {
				transform: none;
			}
		}

		& > .ant-row {
			min-height: 100vh;
		}
	}
	.container {
		height: 100vh;
		background: $neutral-1;

		@media screen and (max-width: $lg) {
			& {
				// height: 450px !important;
				// background: url("../../assets/SignIn_SignUp/CurvedRectangleSalmon.png");
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}
		@media screen and (max-width: $xs) {
			& {
				display: none;
			}
		}

		.title {
			font: $font-xxl;
			max-width: 450px;
			margin: 150px 0 0 88px;
			color: $text-primary;

			@media screen and (max-width: $lg) {
				& {
					margin-left: 45vw;
					font: 900 30px/38px Roboto, sans-serif;
				}
			}
		}

		.feature-image {
			position: absolute;
			bottom: 0;
			height: 70%;
			margin-left: 125px;

			@media screen and (max-width: $lg) {
				& {
					height: 85%;
					margin-left: 10%;
				}
			}
		}
	}

	.form-container {
		max-width: 352px;
		margin: auto;

		@media screen and (max-width: $lg) {
			margin-top: 15vh;
		}

		.form-title {
			font: 700 30px/38px Roboto, sans-serif;
			margin-bottom: $space-lg;
			color: $text-primary;
		}

		.checkbox {
			font: $font-sm;
			font-weight: 400;
			span {
				a {
					color: $podpal-blue;
					&:hover {
						color: $podpal-blue-hover-dark;
					}
				}
			}
		}
		.forgot-password {
			font: $font-md;
			font-weight: 400;
		}
		.already-member-text {
			font: $font-md;
			font-weight: 400;
			color: $text-secondary;
			margin-right: $space-xs;
			line-height: 30px;
		}
		.mobile-get-started {
			@media screen and (min-width: $xs) {
				display: none;
			}
		}
	}
}
