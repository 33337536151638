@import "../../styles/scss/color.scss";
.home-page {
	display: flex;
	flex-direction: row;
	width: 100% !important;
	height: calc(100vh - 64px);
}

.home-page-container,
.banner-page-container {
	transition: width 0.5s ease-in-out;
}

.banner-page-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.home-page-container {
	/* position: relative; */
	padding: 24px;
	overflow: scroll;
	height: 100%;
	width: 100%;
	background-color: $neutral-1;
}

.home-page-heading {
	color: $text-primary;
	margin-top: 8px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	/* identical to box height, or 140% */

	display: flex;
	align-items: center;
}
