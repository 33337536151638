@import "../../../../styles/scss/layout.scss";
@import "../../../../styles/scss/type.scss";
@import "../../../../styles/scss/color.scss";

.new-podcast-form-drawer {
	// .ant-drawer-body {
	//     padding: $space-lg !important;
	// }

	label {
		display: flex;
		align-items: center;
		font: $font-sm !important;
		font-weight: 500 !important;
		line-height: 12px !important;
		color: $text-primary;
		margin-bottom: $space-xs;
	}

	input {
		// box-sizing: border-box;
		border-radius: $radius-md;
		// max-width: 256px;
	}

	textarea {
		// box-sizing: border-box;
		border-radius: $radius-md;
		// max-width: 504px;
		// min-height: 133px;
	}

	// input.ant-input.long-input, textarea.ant-input.long-input {
	//     width: 514px !important;
	// }

	// .input-with-feedback {
	//     width: 256px !important;
	// }

	.fields-container {
		display: flex;
		justify-content: space-between;
	}

	.new-podcast-form-drawer-footer {
		text-align: right;
	}

	.new-podcast-form-drawer-cancel-button {
		margin-right: $space-xs;
	}

	.ant-select-selector {
		width: 100% !important;
	}
}
