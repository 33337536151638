@import "../../../../styles/scss/layout.scss";
@import "../../../../styles//scss/type.scss";
@import "../../../../styles/scss/color.scss";

.apps-and-integrations {
	margin-top: $space-lg;
	& > .ant-row {
		margin-left: 6px !important;
	}

	.success-text {
		font: $font-sm;
		color: $podpal-green-light;
	}
	.check-icon {
		fill: $podpal-green-light;
		svg {
			height: 15px;
			width: 15px;
			vertical-align: inherit;
		}
	}
	.trash-wrapper {
		text-align: center;
		transition: all 0.1s ease-in-out;
	}
	.trash-icon {
		fill: $text-secondary;

		&:hover {
			transition: ease 0.25s;
			box-shadow: 0 0 0 8px $podpal-blue-ghost;
			background: $podpal-blue-ghost;
			border-radius: 200px;
		}
	}

	.action-wrapper {
		min-height: 38px;
	}
}
